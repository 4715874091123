import '~/styles/main.scss';
import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';
import { useRouter } from 'vue-router';
import App from '~/App.vue';

const app = createApp(App);
const router = useRouter();

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
});

(async () => {
  const modules = await import('~/modules');
  Object.values(modules).map(module => {
    return module.install?.({ app });
  });
  app.mount('#app');
})();
