<script setup lang="ts">
import { useHead } from '@vueuse/head';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useInit } from '~/init';

const { t, locale } = useI18n();
const route = useRoute();

useInit();
useHead({
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: 'favicon.ico'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: 'apple-touch-icon-180x180.png'
    },
    {
      rel: 'icon',
      sizes: 'any',
      href: 'favicon.ico',
    },
  ],

  title: computed(() => {
    const routeName = route.name?.toString();
    return t(`${routeName}._meta.title`) === `${routeName}._meta.title`
      ? null
      : t(`${routeName}._meta.title`);
  }),
  titleTemplate: (titleChunk?: string) => {
    return titleChunk ? `${titleChunk} - Opale Capital` : 'Opale Capital';
  }
});

</script>

<template>
  <v-app
    :key="`app.${locale}`"
    theme="opale-light"
  >
    <router-view />
  </v-app>
</template>
