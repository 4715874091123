import { useI18n } from 'vue-i18n';
import { setLocale } from 'yup';

export const initYupLocale = () => {
  const { t } = useI18n();

  setLocale({
    mixed: {
      default:    t('_yup.mixed.default'),
      required:   t('_yup.mixed.required'),
      oneOf:      ({ values }) => t('_yup.mixed.oneOf',     { values }),
      notOneOf:   ({ values }) => t('_yup.mixed.notOneOf',  { values }),
      notType:    ({ type })   => t('_yup.mixed.notType',   { type }),
    },
    string: {
      length:     ({ length }) => t('_yup.string.length', { length }),
      min:        ({ min })    => t('_yup.string.min',    { min }),
      max:        ({ max })    => t('_yup.string.max',    { max }),
      matches:    ({ regex })  => t('_yup.string.regex',  { regex }),
      email:      t('_yup.string.email'),
      url:        t('_yup.string.url'),
      uuid:       t('_yup.string.uuid'),
      trim:       t('_yup.string.trim'),
      lowercase:  t('_yup.string.lowercase'),
      uppercase:  t('_yup.string.uppercase'),
    },
    number: {
      min:        ({ min })  => t('_yup.number.min',      { min }),
      max:        ({ max })  => t('_yup.number.max',      { max }),
      lessThan:   ({ less }) => t('_yup.number.lessThan', { less }),
      moreThan:   ({ more }) => t('_yup.number.moreThan', { more }),
      positive:   t('_yup.number.positive'),
      negative:   t('_yup.number.negative'),
      integer:    t('_yup.number.integer'),
    },
    date: {
      min:        ({ min }) => t('_yup.date.min', { min }),
      max:        ({ max }) => t('_yup.date.max', { max }),
    },
    boolean: {
      isValue:    ({ value }) => t('_yup.boolean.isValue', { value }),
    },
    object: {
      noUnknown:  t('_yup.object.noUnknown'),
    },
    array: {
      min:        ({ min }) => t('_yup.array.min', { min }),
      max:        ({ max }) => t('_yup.array.max', { max }),
    },
  });
};
