import { useRouter } from 'vue-router';

export const initGuards = async () => {
  const router = useRouter();
  const accessibleRoutes = [
    '/investor/type',
    '/partner/personal-information',
  ];

  const allowedRouteNames = ['activate', 'forgotten-password', 'reset-password'];

  router.beforeEach((to, from, next) => {
    if (undefined === from.name && to.path === '/') {
      next();
    }
    else if (
      undefined === from.name
      && !accessibleRoutes.includes(to.path)
      && !allowedRouteNames.includes(to.name as string)
    ) {
      next('/');
    }
    else {
      next();
    }
  });
};
